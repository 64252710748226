import React from "react";

const Lokalizacja = ({nextStep, handleLokalizacja, data, kurier}) => {
    let locations = data.filter(item => item[1].courier.courierName === kurier && item[1].products.length === 1);
    let locations2 = locations.map(item => item[1].products[0].location && item[1].products[0].location.toUpperCase().split('-')[0]);
    console.log(locations2);
    let uniqueLocations = [...new Set(locations2)];
    console.log(uniqueLocations);

    const handleChoose = (val) => {
        nextStep(1);
        handleLokalizacja(val);
    }

    let locationsOrder = ['A','B','C','D','E','XS', 'S', 'M', 'L', 'XL', 'JEANS I DRESS', 'BONY', 'POZOSTAŁE', 'WSZYSTKIE', 'PIĘTRO', 'BRAK LOKALIZACJI', 'DOSTAWA', 'BRANSOLETKI'];
    let uniqueLocationsSorted = uniqueLocations.sort((a,b) => {
        let aSize = a;
        let bSize = b;
        return locationsOrder.indexOf(aSize) - locationsOrder.indexOf(bSize);
    });
    console.log(uniqueLocationsSorted);

    const locationColor = (color) => {
        switch(color) {
            case 'XS':
                return 'rgb(27,152,150)';
            case 'S':
                return 'rgb(37,132,26)';
            case 'M':
                return 'rgb(192,26,91)';
            case 'L':
                return 'rgb(253,181,13)';
            case 'XL':
                return 'rgb(22,37,57)';
            default:
                return '';
        }
    }

    const changeName = (item) => {
        switch(item) {
            case 'A':
                return 'XS';
            case 'B':
                return 'S';
            case 'C':
                return 'M';
            case 'D':
                return 'L';
            case 'E':
                return 'XL';
            default:
                return item;
        }
    }

    return (
        <>
            <header>
                <h4>Wybierz lokalizację</h4>
            </header>
            <div className="buttons location">
                {uniqueLocations ? uniqueLocations.map(item => (
                    <React.Fragment key={item}>
                    {item !== undefined ? (<div key={item} onClick={() => handleChoose(item)} className={(item === 'A' || item === 'B' || item === 'C' || item === 'D' || item === 'E' || item === 'POZOSTAŁE' || item === 'JEANS I DRESS' || item === 'BONY') ? 'half' : ''}><div style={{backgroundColor: locationColor(changeName(item))}}>{changeName(item)}</div></div>) : null}
                    </React.Fragment>
                )) : 'brak lokalizacji'}
            </div>
        </>
    )
}

export default Lokalizacja;
import { useState, useEffect, useContext } from 'react';
import Loading from '../components/Loading';
import Stanowisko from '../components/Stanowisko';
import Rodzaj from '../components/Rodzaj';
import Kurier from '../components/Kurier';
import Zamowienia from '../components/Zamowienia';
import Lokalizacja from '../components/Lokalizacja';
import ListaPakowane from '../components/ListaPakowane';
import ListaSpakowane from '../components/ListaSpakowane';
import WyborZamowien from '../components/WyborZamowien';
import './Home.css';

import { AuthContext } from '../context/authContext';
import { API_URL } from '../utils/api';

function Home() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [step, setStep] = useState(0);
  const [stanowisko, setStanowisko] = useState(null);
  const [rodzaj, setRodzaj] = useState(null);
  const [kurier, setKurier] = useState(null);
  const [zamowienia, setZamowienia] = useState(null);
  const [lokalizacja, setLokalizacja] = useState(null);
  const [successList, setSuccesList] = useState([]);
  const [refreshDate, setRefreshDate] = useState(null);
  const [wybraneZamowienia, setWybraneZamowienia] = useState([]);

  const { onLogout } = useContext(AuthContext);

  const fetchData = async () => {
    const response = await fetch(`${API_URL}/iai/getOrders?status=packed`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    });
    const json = await response.json();

    const getData = Object.entries(json);

    let currentDate = new Date();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes() < 10 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    setData(getData);
    setRefreshDate(hours + ':' + minutes);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const prevStep = () => {
    if(step !== 0) {
      if(step === 5 && rodzaj === 'wybrane zam.') {
        setStep(step - 3);
      } else if(step === 6 && rodzaj === 'pakowanie') {
        setStep(step - 5);
      } else if(step === 5 && lokalizacja === 'wszystkie') {
        setStep(step - 2);
      } else {
        if(step === 1) {
          setRodzaj(null);
        }
        if(step === 2) {
          setStanowisko(null);
        }
        if(step === 3) {
          setKurier(null);
        }
        if(step === 4) {
          setZamowienia(null);
          setLokalizacja(null);
        }
        setStep(step - 1);
      }
    }
  }

  const nextStep = (num) => {
    if(step < 5) {
      setStep(step + num);
    }
  }

  const handleStanowisko = (i) => {
    setStanowisko(i);
  }

  const handleRodzaj = (val) => {
    setRodzaj(val);
  }

  const handleWybrane = (val) => {
    setWybraneZamowienia(val);
  }

  const handleKurier = (val) => {
    setKurier(val);
  }

  const handleZamowienia = (val) => {
    setZamowienia(val);
  }

  const handleLokalizacja = (val) => {
    setLokalizacja(val);
  }

  const handleSetSuccesList = (val) => {
    setSuccesList(val);
  }

  const refreshOrders = () => {
    setLoading(true);
    fetchData();
  }

  const stepContent = (step) => {
    switch (step) {
      case 0:
        return <Rodzaj nextStep={nextStep} handleRodzaj={handleRodzaj} data={data} />;
      case 1:
        return <Stanowisko nextStep={nextStep} handleStanowisko={handleStanowisko} data={data} rodzaj={rodzaj} />;
      case 2:
        return rodzaj === 'wybrane zam.' ? <WyborZamowien nextStep={nextStep} data={data} handleWybrane={handleWybrane} /> : <Kurier nextStep={nextStep} handleKurier={handleKurier} data={data}/>;
      case 3:
        return <Zamowienia nextStep={nextStep} handleZamowienia={handleZamowienia} handleLokalizacja={handleLokalizacja} data={data} />;
      case 4:
        return <Lokalizacja nextStep={nextStep} handleLokalizacja={handleLokalizacja} data={data} kurier={kurier} />;
      case 5:
        return <ListaPakowane nextStep={nextStep} data={data} kurier={kurier} rodzaj={rodzaj} lokalizacja={lokalizacja} zamowienia={zamowienia} stanowisko={stanowisko} handleSetSuccesList={handleSetSuccesList} successList={successList} wybraneZamowienia={wybraneZamowienia} />;
      case 6:
        return <ListaSpakowane stanowisko={stanowisko} />;
      default:
        return 'error';
    }
  }

  return (
    <>
      {loading ? <Loading /> : (
        <>
          <div className={step === 4 ? 'App location' : 'App'}>
            <div className="choices">
              <strong>Rodzaj:</strong> {rodzaj ? rodzaj : 'nie wybrano'},
              <strong>Kurier:</strong> {kurier ? kurier : 'nie wybrano'},
              <strong>Zam.:</strong> { zamowienia ? zamowienia : 'nie wybrano'},
              <strong>Lok.:</strong> { lokalizacja ? lokalizacja : 'nie wybrano'}
            </div>
            {stepContent(step)}
          </div>
          <footer className={step === 0 ? 'home' : ''}>
            <div className="footerButtons">
              { step !== 0 ? (
                <button className="prevStep" onClick={prevStep}>powrót</button>
              ) : null }
              { step === 0 ? (
                <button className="prevStep" onClick={() => onLogout('logout')}>wyloguj się</button>
              ) : null }
              { step !== 6 && (
                <button className="prevStep" onClick={refreshOrders}>odśwież listę zamówień<span>(ostatnia aktualizacja: {refreshDate})</span></button>
              )}
            </div>
            { step === 0 && (
              <div className="footerVersion">build 01.08</div>
            )}
          </footer>
        </>
      )}
    </>
  );
}

export default Home;
